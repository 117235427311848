import { ParsedUrlQueryInput } from "querystring";
import { ProductTypeFilterSetting, ProductVariantAttributesFragment, ProductVariantFragment } from "./fragments"

export const productFilterSettingToQuery = (filter: ProductTypeFilterSetting) : ParsedUrlQueryInput => {
	
	return {
		...filter,
		colors: filter.colors?.join(',') ?? null,
		surfaces: filter.surfaces?.join(',') ?? null,
		priceRange: filter.priceRange ? JSON.stringify(filter.priceRange) : null,

		widthRange: filter.widthRange ? `${filter.widthRange.min}-${filter.widthRange.max}` : null,
		heightRange: filter.heightRange ? `${filter.heightRange.min}-${filter.heightRange.max}` : null,
		lengthRange: filter.lengthRange ? `${filter.lengthRange.min}-${filter.lengthRange.max}` : null,

		attributes: Object.keys(filter.attributes ?? {})
	};
};

export const extractProductFilterSettingFromQuery = (queryParams: ParsedUrlQueryInput) : ProductTypeFilterSetting | null =>
{
	const param = JSON.parse(JSON.stringify(queryParams)) as ParsedUrlQueryInput;
	delete param['page'];
	delete param['tab'];
	delete param['reference'];
	delete param['locale'];
	delete param['id'];

	const getRange = (str : string | null) => {

		if(!str)
		{
			return null;
		}

		const parts = str.split('-');
		if(parts.length != 2)
		{
			return null;
		}

		const range = {
			min: parseInt(parts[0]),
			max: parseInt(parts[1])
		};

		if(isNaN(range.min) || isNaN(range.max) || range.min > range.max)
		{
			return null;
		}

		return range;
	};
	
	let price = param.priceRange ? JSON.parse(param.priceRange as string) : null;
	if(price && (Object.keys(price).length != 4 || !price.maxCents || !price.minCents || !price.currency || !price.region))
	{
		price = null;
	}

	const filters = Object.keys(param).length > 0 ? {
		...param,
		colors: (param.colors as string)?.split(',').filter(c => c) ?? null,
		surfaces: (param.surfaces as string)?.split(',').filter(s => s) ?? null,
		priceRange: price,

		widthRange: getRange(param.widthRange as string),
		heightRange: getRange(param.heightRange as string),
		lengthRange: getRange(param.lengthRange as string),

		attributes: Object.fromEntries((param.attributes as string[] ?? []).map(a => [a, true]))
	} as ProductTypeFilterSetting : null;

	return filters;
};

const roundToTwoDecimalPlaces = (input: number) =>
	Math.round(input * 100) / 100

export const computeProductCount = (
	numberValue: number,
	preferredUnits: boolean,
	quantityPerSqm: number,
) =>
	Math.round(
		100 * (numberValue * (preferredUnits ? 1 / quantityPerSqm : quantityPerSqm)),
	) / 100

export const roundProductQuantity = (
	numberValue: number,
	preferredUnits: boolean,
	quantityPerSqm: number,
	quantityPerLayer: number | undefined,
) => {
	const currentCount = preferredUnits
		? numberValue
		: computeProductCount(numberValue, preferredUnits, quantityPerSqm)
	const roundedCount = quantityPerLayer
		? Math.ceil(currentCount / quantityPerLayer) * quantityPerLayer
		: Math.round(currentCount)

	const numberValueAfterRounding =
		currentCount === roundedCount
			? numberValue
			: preferredUnits
			? roundedCount
			: roundToTwoDecimalPlaces(roundedCount / quantityPerSqm)

	const valueAfterRounding = numberValueAfterRounding
		? `${numberValueAfterRounding}`
		: ''

	return {
		number: numberValueAfterRounding,
		string: valueAfterRounding,
	}
}

const EPSILON = 1e-10

const looseCompare = <T extends any>(valA: T, valB: T): boolean => {
	if (valA === valB) {
		return true
	}
	if (typeof valA === 'number' && typeof valB === 'number') {
		const diff = Math.abs(valA - valB)
		return (
			diff < EPSILON ||
			diff / Math.max(Math.abs(valA), Math.abs(valB)) < EPSILON
		)
	}
	return false
}
