import classNames from 'classnames'
import React from 'react'
import { Align } from '../src/model/fragments'

export type BarelyVisibleTitleProps = 
{
	line?: boolean
	align?: Align
	level?: 1 | 2 | 3 | 4 | 5 | 6
}

export const BarelyVisibleTitle: React.FunctionComponent<BarelyVisibleTitleProps> = ({ align, line, level, children }) => {
	const Heading = (level ? `h${level}` : 'h1') as 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
	return <Heading className={classNames('barelyVisibleTitle', align ? ('barelyVisibleTitle-' + align) : '', line ? 'barelyVisibleTitle-line' : '')}>{children}</Heading>
}

