import cn from 'classnames'
import React from 'react'
import { Card, CardProps, CardView } from './Card'
import { FlexiLink, FlexiLinkProps } from './FlexiLink'
import { Cross } from './Cross'

export interface CardBoxProps {
	FlexiLinkProps?: FlexiLinkProps
	hrefText?: string
	heroImage?: CardProps['heroImage']
	title?: string
	date?: string
	content?: string | React.ReactNode
	categoryTag?: React.ReactNode
	onDismiss?: (event: React.MouseEvent<HTMLButtonElement>) => void
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
	view?: CardView
	picture?: React.ReactNode
	footer?: React.ReactNode
	isActive?: boolean
}

export const CardBox: React.FunctionComponent<CardBoxProps> = (props) => {
	const {
		FlexiLinkProps,
		hrefText,
		heroImage,
		title,
		date,
		content,
		categoryTag,
		onDismiss,
		onClick,
		view,
		picture,
		footer,
		isActive
	} = props

	return (
		<section className={cn('cardBox', `view-${view || CardView.default}`, isActive ? 'cardBox-active' : '')}>
			<Card
				view={view}
				clickable={Boolean(FlexiLinkProps || onClick)}
				heroImage={heroImage}
			>
				{onClick && (
					<button
						type="button"
						className="cardBox-button"
						aria-label={title}
						onClick={onClick}
					/>
				)}
				{FlexiLinkProps && (
					<FlexiLink {...FlexiLinkProps}>
						<a className="cardBox-link" aria-label={title} />
					</FlexiLink>
				)}
				{picture}
				<div className="cardBox-wrapper">
					{(title || date || onDismiss) && (
						<div className="cardBox-head">
							{title && <h4 className="cardBox-title">{title}</h4>}
							{date && <time className="cardBox-date">{date}</time>}
							{onDismiss && (
								<button
									className="cardBox-cross"
									onClick={onDismiss}
									aria-label="Zavřít"
								>
									<Cross />
								</button>
							)}
						</div>
					)}
					{categoryTag && <div className="cardBox-tag">{categoryTag}</div>}
					{content && (typeof content === 'string') && <div className="cardBox-content" dangerouslySetInnerHTML={{ __html: content ?? '' }}></div>}
					{content && (typeof content !== 'string') && <div className="cardBox-content">{ content }</div>}
					{((FlexiLinkProps && hrefText) || footer) && (
						<div className="cardBox-footer">
							{FlexiLinkProps && hrefText && (
								<FlexiLink {...FlexiLinkProps}>
									<a className="cardBox-link-text">{hrefText}</a>
								</FlexiLink>
							)}
							{footer}
						</div>
					)}
				</div>
			</Card>
		</section>
	)
}
