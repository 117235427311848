import React, { ReactNode } from 'react'
import RILightbox from 'react-image-lightbox'

export interface LightboxItem {
	url: string
	label?: string | ReactNode
	title?: ReactNode
}

export interface LightboxProps {
	items: LightboxItem[]
	index: number
	setIndex: (index: number) => void
	onCloseRequest: () => void
	onChangeRequest?: (index: number) => void
}

export const Lightbox: React.FunctionComponent<LightboxProps> = ({
	items,
	index,
	setIndex,
	onCloseRequest,
	onChangeRequest
}) => {


	return (
		<RILightbox
			enableZoom={true}
			mainSrc={items[index].url}
			imageTitle={items[index].title}
			imageCaption={items[index].label}
			nextSrc={
				index < items.length - 1
					? items[(index + 1) % items.length].url
					: undefined
			}
			prevSrc={
				index > 0
					? items[(index + items.length - 1) % items.length].url
					: undefined
			}
			onCloseRequest={onCloseRequest}
			onMovePrevRequest={() => {

				setIndex((index + items.length - 1) % items.length)

				if(onChangeRequest)
				{
					onChangeRequest(index);
				}
				
			}}
			onMoveNextRequest={() => {

				setIndex((index + 1) % items.length)

				if(onChangeRequest)
				{
					onChangeRequest(index);
				}
				
			}}
			
		/>
	)
}
